import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import ServicePhoto from "../images/services-photo.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeaderText, InfoContainerEven, InfoContainerOdd, InfoHeader, InfoInside, Info, Blue, Red } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0.61) 35.42%,
    rgba(51, 121, 200, 0.85) 99.48%),
    url(${ServicePhoto});
  background-size: cover;
  display: flex;
`

const SpiritualGrowth = () => (
  <Layout>
    <SEO title="Spiritual Growth" />
    <Grid container>
      <TitleImage>
        <PageHeaderText>Spiritual Growth</PageHeaderText>
      </TitleImage>
      <InfoContainerEven>
        <InfoInside>
          <InfoHeader>
            <Blue>Christian Formation</Blue>
          </InfoHeader>
          <Info>
            At St. Alban’s we strive to grow in our relationship with God, both
            as individuals and as church. A key part of that growth is Christian
            formation/education. Christian formation is a lifelong process of
            learning, developing and growing, which includes education for both
            children and adults. But true Christian Formation is actually more
            than merely a process of learning and acquiring knowledge or
            information. It involves forming us as disciples of Jesus Christ and
            equipping us to “do the work (he has) given us to do; to love and
            serve (God) as faithful witnesses of Christ our Lord.” BCP pg. 366.
          </Info>
          <Info>
            <Red>
              Our Christian formation/education hour is held September –May and
              begins at 9:00 AM, between the two services.{" "}
            </Red>
          </Info>
        </InfoInside>
      </InfoContainerEven>
      <InfoContainerOdd>
        <InfoInside>
          <InfoHeader>
            <Blue>Sunday School</Blue> 2019-2020
          </InfoHeader>
          <Info>
            Sunday school is currently on hold. We will resume again in the
            fall.
          </Info>
        </InfoInside>
      </InfoContainerOdd>
    </Grid>
  </Layout>
)

export default SpiritualGrowth